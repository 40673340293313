
body {
  font-family: sans-serif;
}

/* https://stackoverflow.com/questions/4617872/white-space-showing-up-on-right-side-of-page-when-background-image-should-extend */
body, html {
  /* NOTES on height: 100%
    - Enabling height for body only will make scrollTo work again, but modals will scroll as well
    - Momentum scroll does not work when this is enabled for both body and html!
  */
  /* height: 100%; */
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; /* Need this to have no space showing up on right side */
}

html {
  box-sizing: border-box;
}

/* Remove calendar focus */
/* https://stackoverflow.com/questions/3660831/getting-rid-of-the-blue-focus-rectangle-on-input-boxes-in-html-css */
td:focus {
  outline: none;
}

.hoverable:hover {
  cursor: pointer;
}

.bluelink {
  color: #4d89e9;
  font-weight: bold;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* https://stackoverflow.com/questions/22940761/best-way-to-create-an-a-link-with-empty-href */
a:hover, .link:hover, .bluelink:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: inherit;
}

a, .link {
  color: inherit;

  /* https://stackoverflow.com/questions/2789703/remove-stubborn-underline-from-link */
  text-decoration: none;
  font-weight: bold;
}

.invisibleLink:hover {
  color: inherit;
  text-decoration: none;
}

.pointerOnHover:hover {
  cursor: pointer;
}

.modalBackdrop {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

/* https://stackoverflow.com/questions/9538868/prevent-body-from-scrolling-when-a-modal-is-opened */
.modal-open {
  overflow: hidden !important;
}

.gradientTransparent {
  /* https://stackoverflow.com/questions/15597167/css3-opacity-gradient */
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));

  /* https://stackoverflow.com/questions/5269713/css-ellipsis-on-second-line */
  text-overflow: ellipsis;
}

.gradientMap {
  /* https://www.w3schools.com/css/css3_gradients.asp */
  /* -webkit-mask-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%, rgba(0,0,0,0)); */
}

/* For custom calendar rendering. */
.calendar-date {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* Unavailable / compatible background colors */
.calendar-compatible {
  background: #ddffdd;
}
.vehicle-calendar-compatible {
  background: #ddf3f7;
}
.calendar-compatible:hover,
.vehicle-calendar-compatible:hover {
  color: #fff;
  background: #00a699;
}
.vehicle-calendar-unavailable,
.vehicle-calendar-unavailable-nohover {
  background: #ff7777;
}
.vehicle-calendar-unavailable:hover {
  background: #dd6677;
}

/* Blocked date */
.calendar-blocked-date,
.calendar-blocked-date:hover {
  height: 100%;

  background: #fff;
}
.calendar-blocked-date-partial {
  height: calc(100% - 12px);
  width: 100%;

  background: #99eeaa;
}
.calendar-blocked-date-full {
  height: 100%;
  width: 100%;

  background: #ff7777;
}
.calendar-blocked-date-start {
  margin-left: 6px;
}
.calendar-blocked-date-end {
  margin-right: 6px;
}

/* Info on date */
.calendar-date-info0 {
  height: 100%;
  position: relative;
}
/* https://css-tricks.com/the-shapes-of-css/ */
.calendar-date-info {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 12px solid #ff9100;
  border-left: 12px solid transparent;
}
.calendar-date-order-info {
  position: absolute;
  bottom: 6px;
  left: 6px;
  width: 0;
  height: 0;
  border-bottom: 12px solid #4d89e9;
  border-right: 12px solid transparent;
}
.calendar-regular-text {
  color: #484848;
}

/* Fix hover colors and borders
All syles:
https://github.com/airbnb/react-dates/blob/a04dfd1ad15939e1436a2181e0304899b539801a/src/components/CalendarDay.jsx
https://gist.github.com/MonsieurV/b23403275b7ed6d71c68254398ae956d
*/

/* Superclass */
.CalendarDay__default {
  padding: 0px !important;
  border: 1px solid #eee !important;
  background: #fff;
}
/* Grey on hover */
.CalendarDay__default:hover {
  background: #eee !important;
}
/* Darkeon from 00a699 on hover */
.CalendarDay__selected {
  background: #00a699 !important;
}
.CalendarDay__selected:hover {
  background: #009088 !important;
}
.CalendarDay__blocked_calendar {
  color: #484848 !important;
  background: #fff !important;
}
.CalendarDay__selected_span {
  color: #fff !important;
  border: 1px solid #33dacd !important;
  background: #66e2da !important;
}
/* No hover effect */
.CalendarDay__blocked_out_of_range:hover,
.CalendarDay__blocked_calendar:hover {
  background: #fff !important;
}
/* Delete weird hover border and text effect */
.CalendarDay__hovered_span {
  color: #484848 !important;
  background: #fff !important;
}

/* https://stackoverflow.com/questions/11064237/prevent-iphone-from-zooming-form */
/* Problem: Zooms in when selecting location & date, won't zoom out and the view remains broken */
input, select, textarea {
    font-size: 16px !important;
}
